<template>
  <div class="space-y-10">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-12 mt-4">
      <!-- about -->
      <div class="space-y-8 col-span-full sm:col-span-1">
        <h1 class="font-semibold text-3xl">About Us</h1>
        <p>
          Consignment vacation packages from
          <strong> {{ $store.state.selectedPartner.name }}</strong>
          may be the easiest items that you offer in your next silent or live
          auction. There is no collecting donated items, no heavy framed items
          to handle, and no fear of damaging a consigned, or worse a sold item.
          With our vacations you
          <strong>just print it, display it and profit from it.</strong>
          All of this with <strong>no risk to your organization.</strong>
        </p>
        <p>
          You can select your trips weeks in advance and advertise them long
          before your event, or add them last-minute to complete your auction.
          <strong>The process is really that simple.</strong>
        </p>
        <p>
          You can give us a call or send us an e-mail, and we will help you
          select the right packages for your silent or live auction. Or, you can
          simply view the packages and decide which and how many you want to
          offer. Then just print it and add it to your auction, at a price that
          guarantees you a profit. When the winning bidder is determined collect
          their name and e-mail address and complete the post event report here
          on our website. All of this happens at
          <strong>no risk to your organization and a great profit.</strong>
        </p>
        <p>
          As you continue through this website please remember that our only
          goals are to help your organization reach its financial goals and
          provide your supporters the best vacation experience possible.
          <strong
            >Please feel free to
            <router-link
              class="href font-bold"
              :to="'/' + $store.state.selectedPartner.id + '/contact'"
            >
              contact us anytime</router-link
            ></strong
          >
          to discuss your upcoming events and how we might help you to achieve
          your goals.
        </p>
      </div>
      <!-- how it works -->
      <div class="col-span-full sm:col-span-1">
        <HowItWorks />
      </div>
      <!-- best sellers -->
      <div class="col-span-2 space-y-10">
        <div>
          <hr />
        </div>
        <div class="pageTitle">
          <h1>Our Best Selling Packages</h1>
          <h3>A great starting point for any event</h3>
        </div>
        <RetailPackages :items="$store.state.retailPackages" />
      </div>
    </div>
  </div>
</template>

<script>
import RetailPackages from "@/components/RetailPackages";
import HowItWorks from "@/components/HowItWorks";
export default {
  components: { RetailPackages, HowItWorks },
};
</script>
